var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full"},[_c('div',{staticClass:"domain-info mb-8"},[_c('select-filter',{attrs:{"labelname":"Domain","options":_vm.domainOptions,"optionsName":"domainName","value":_vm.domainSelected},on:{"input":function (newDomain) {
              _vm.domainSelected = newDomain;
            }}}),_c('date-range-picker',{attrs:{"fromDateValue":_vm.fromDate,"toDateValue":_vm.toDate},on:{"inputFromDate":function (newFromDate) {
              _vm.fromDate = newFromDate;
            },"inputToDate":function (newToDate) {
              _vm.toDate = newToDate;
            }}}),_c('only-company',{attrs:{"onlyCompaniesValue":_vm.onlyCompanies},on:{"input":function (newonlyCompanies) {
              _vm.onlyCompanies = newonlyCompanies;
            }}}),_c('vs-button',{staticClass:"small-btn bg-actionbutton",attrs:{"type":"filled"},on:{"click":function($event){return _vm.updateData()}}},[_vm._v(_vm._s(_vm.LabelConstant.buttonLabelUpdate))])],1)])]),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full col-cus-12  mb-8 xl:w-2/3"},[_c('three-xaxis-chart',{attrs:{"chartTitle":'Site Traffic Overview',"chartType":'line',"chartHeight":'350',"seriesNameOne":'Companies Identified',"seriesDataOne":_vm.siteTrafficOverviewChartChartSeriesDataOne,"seriesNameTwo":'Unique Visitors',"seriesDataTwo":_vm.siteTrafficOverviewChartChartSeriesDataTwo,"seriesNameThree":'Page Views',"seriesDataThree":_vm.siteTrafficOverviewChartChartSeriesDataThree,"optionsXaxis":_vm.siteTrafficOverviewChartChartOptionsXaxis}})],1),_c('div',{staticClass:"vx-col w-full col-cus-12  mb-8 xl:w-4/12"},[_c('two-column-chart',{attrs:{"chartTitle":'First Time vs Return Visitors',"chartType":'bar',"chartHeight":'350',"seriesNameOne":'First Time Visitors',"seriesDataOne":_vm.firstTimeOrReturnVisitorChartSeriesDataOne,"seriesNameTwo":'Return Visitors',"seriesDataTwo":_vm.firstTimeOrReturnVisitorChartSeriesDataTwo,"optionsXaxis":_vm.firstTimeOrReturnVisitorChartOptionsXaxis}})],1)]),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full mb-8 col-cus-12 xl:w-2/3"},[_c('vx-card',{staticClass:"h-full"},[_c('div',{staticClass:"card-title"},[_c('h2',[_vm._v("Visitor Location Overview")])]),[_c('GChart',{attrs:{"settings":{
              packages: ['corechart', 'table', 'geochart'],
              mapsApiKey: _vm.googleMapApiKey
            },"type":"GeoChart","data":_vm.chartData,"options":_vm.chartOptions}})]],2)],1),_c('div',{staticClass:"vx-col w-full col-cus-12 xl:w-4/12"},[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col mb-8 vx-col w-full lg-cus-6"},[_c('two-column-chart',{attrs:{"chartTitle":'Engaged Vs Bounced Visitors',"chartType":'bar',"chartHeight":'300',"seriesNameOne":'Engaged Visitors',"seriesDataOne":_vm.engagedOrBouncedVisitorsChartSeriesDataOne,"seriesNameTwo":'Bounced Visitors',"seriesDataTwo":_vm.engagedOrBouncedVisitorsChartSeriesDataTwo,"optionsXaxis":_vm.engagedOrBouncedVisitorsChartOptionsXaxis}})],1),_c('div',{staticClass:"vx-col mb-8 vx-col w-full lg-cus-6"},[_c('two-column-chart',{attrs:{"chartTitle":'Referrals Vs Direct Visitors',"chartType":'bar',"chartHeight":'300',"seriesNameOne":'Referral',"seriesDataOne":_vm.referralOrDirectVisitorsChartSeriesDataOne,"seriesNameTwo":'Direct',"seriesDataTwo":_vm.referralOrDirectVisitorsChartSeriesDataTwo,"optionsXaxis":_vm.referralOrDirectVisitorsChartOptionsXaxis}})],1)])])]),_c('help-modal',{attrs:{"popupActive":_vm.popupActive},on:{"close":_vm.closepopup}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }