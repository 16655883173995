<template>
  <div>
    <!-- Top Domain Details -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="domain-info mb-8">
          <!-- Domain -->
          <select-filter
            labelname="Domain"
            :options="domainOptions"
            optionsName="domainName"
            :value="domainSelected"
            @input="
              newDomain => {
                domainSelected = newDomain;
              }
            "
          ></select-filter>
          <!-- Date Picker -->
          <date-range-picker
            :fromDateValue="fromDate"
            :toDateValue="toDate"
            @inputFromDate="
              newFromDate => {
                fromDate = newFromDate;
              }
            "
            @inputToDate="
              newToDate => {
                toDate = newToDate;
              }
            "
          ></date-range-picker>
          <!-- Only Companies checkbox -->
          <only-company
            :onlyCompaniesValue="onlyCompanies"
            @input="
              newonlyCompanies => {
                onlyCompanies = newonlyCompanies;
              }
            "
          ></only-company>
          <!-- Update Dashboard -->
          <vs-button            
            type="filled"
            @click="updateData()"
            class="small-btn bg-actionbutton"
            >{{ LabelConstant.buttonLabelUpdate }}</vs-button
          >
        </div>
      </div>
    </div>
    <div class="vx-row">
      <!-- Site Traffic Overview -->
      <div class="vx-col w-full col-cus-12  mb-8 xl:w-2/3">
        <three-xaxis-chart
          :chartTitle="'Site Traffic Overview'"
          :chartType="'line'"
          :chartHeight="'350'"

          :seriesNameOne="'Companies Identified'"
          :seriesDataOne="siteTrafficOverviewChartChartSeriesDataOne"
          
          :seriesNameTwo="'Unique Visitors'"
          :seriesDataTwo="siteTrafficOverviewChartChartSeriesDataTwo"

          :seriesNameThree="'Page Views'"
          :seriesDataThree="siteTrafficOverviewChartChartSeriesDataThree"
          
          :optionsXaxis="siteTrafficOverviewChartChartOptionsXaxis"
        ></three-xaxis-chart>
      </div>
      <!-- First Time Vs Return Visitors -->
      <div class="vx-col w-full col-cus-12  mb-8 xl:w-4/12">
        <two-column-chart
          :chartTitle="'First Time vs Return Visitors'"
          :chartType="'bar'"
          :chartHeight="'350'"

          :seriesNameOne="'First Time Visitors'"
          :seriesDataOne="firstTimeOrReturnVisitorChartSeriesDataOne"
          
          :seriesNameTwo="'Return Visitors'"
          :seriesDataTwo="firstTimeOrReturnVisitorChartSeriesDataTwo"
          
          :optionsXaxis="firstTimeOrReturnVisitorChartOptionsXaxis"
        ></two-column-chart>
      </div>
    </div>
    <div class="vx-row">
      <!-- Visitor Location Overview -->
      <div class="vx-col w-full mb-8 col-cus-12 xl:w-2/3">
        <vx-card class="h-full">
          <div class="card-title">
            <h2>Visitor Location Overview</h2>
          </div>
          <template>
            <GChart
              :settings="{
                packages: ['corechart', 'table', 'geochart'],
                mapsApiKey: googleMapApiKey
              }"
              type="GeoChart"
              :data="chartData"
              :options="chartOptions"
            />
          </template>
        </vx-card>
      </div>
      <div class="vx-col w-full col-cus-12 xl:w-4/12">
        <div class="vx-row">
          <!-- Engaged Vs Bounced Visitors -->
          <div class="vx-col mb-8 vx-col w-full lg-cus-6">
            <two-column-chart
              :chartTitle="'Engaged Vs Bounced Visitors'"
              :chartType="'bar'"
              :chartHeight="'300'"

              :seriesNameOne="'Engaged Visitors'"
              :seriesDataOne="engagedOrBouncedVisitorsChartSeriesDataOne"
              
              :seriesNameTwo="'Bounced Visitors'"
              :seriesDataTwo="engagedOrBouncedVisitorsChartSeriesDataTwo"
              
              :optionsXaxis="engagedOrBouncedVisitorsChartOptionsXaxis"
            ></two-column-chart>
          </div>
          <!-- Referrals Vs Direct Visitors -->
          <div class="vx-col mb-8 vx-col w-full lg-cus-6">
            <two-column-chart
              :chartTitle="'Referrals Vs Direct Visitors'"
              :chartType="'bar'"
              :chartHeight="'300'"

              :seriesNameOne="'Referral'"
              :seriesDataOne="referralOrDirectVisitorsChartSeriesDataOne"
              
              :seriesNameTwo="'Direct'"
              :seriesDataTwo="referralOrDirectVisitorsChartSeriesDataTwo"
              
              :optionsXaxis="referralOrDirectVisitorsChartOptionsXaxis"
            ></two-column-chart>
          </div>
        </div>
      </div>
    </div>
    <!-- Help popup -->
    <help-modal :popupActive="popupActive" @close="closepopup"></help-modal>
  </div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";

/* Filter */
import SelectFilter from "../../components/filter/SelectFilter";
import DateRangePicker from "../../components/filter/DateRangePicker";
import OnlyCompany from "../../components/filter/OnlyCompany";

/* chart */
import ThreeXaxisChart from '../../components/charts/ThreeXaxisChart.vue';
import TwoColumnChart from '../../components/charts/TwoColumnChart.vue';
/* Map */
import { GChart } from "vue-google-charts";
//Help Model
import HelpModal from "../../components/modal/HelpModal";

export default {
  components: {
    VxCard,
    SelectFilter,
    DateRangePicker,
    OnlyCompany,
    GChart,
    HelpModal,
    ThreeXaxisChart,
    TwoColumnChart
  },
  data() {
    let uid = this.$route.params.id;
    let VVtoken = localStorage.getItem("VVtoken");
    let token = JSON.parse(VVtoken);
    return {
      googleMapApiKey: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
      popupActive: false,
      /* Filter Select */
      domainSelected: { domainName: "All" },
      //Domain dropdown options
      domainOptions: [],

      //From Date
      fromDate: null,
      //To Date
      toDate: null,

      //Only Companies
      onlyCompanies: false,

      //Site Traffic Overview Chart Series and Options
      siteTrafficOverviewChartChartSeriesDataOne: [],
      siteTrafficOverviewChartChartSeriesDataTwo: [],
      siteTrafficOverviewChartChartSeriesDataThree: [],
      siteTrafficOverviewChartChartOptionsXaxis: [],

      //First Time Vs Return Visitors Chart Series and Options
      firstTimeOrReturnVisitorChartSeriesDataOne: [],
      firstTimeOrReturnVisitorChartSeriesDataTwo: [],
      firstTimeOrReturnVisitorChartOptionsXaxis: [],

      //Engaged Vs Bounced Visitors Chart Series and Options
      engagedOrBouncedVisitorsChartSeriesDataOne: [],
      engagedOrBouncedVisitorsChartSeriesDataTwo: [],
      engagedOrBouncedVisitorsChartOptionsXaxis: [],

      //Referrals Vs Direct Visitors Chart Series and Options
      referralOrDirectVisitorsChartSeriesDataOne: [],
      referralOrDirectVisitorsChartSeriesDataTwo: [],
      referralOrDirectVisitorsChartOptionsXaxis: [],

      /* Map */
      chartData: [["Country", "Unique Visitors"]],
      chartOptions: { colorAxis: { colors: [token[uid].themeDetail.tempChartColor1] } }
    };
  },
  created() {
    this.setFromDate();
    this.setToDate();

    this.getZoneListForFilter();
    this.getStatsSummary();
    this.getRegionMapData();
  },
  methods: {
    closepopup() {
      this.popupActive = false;
    },
    updateData() {
      this.getStatsSummary();
      this.getRegionMapData();
    },
    //Get Domain
    getZoneListForFilter() {
      this.$vs.loading();
      this.axios
        .get("/ws/Zone/GetZoneListForFilter")
        .then(response => {
          let data = response.data;

          for (let i = 0; i < data.length; i++) {
            this.domainOptions.push({
              domainName: data[i]
            });
          }

          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //Site Traffic Overview, First Time Vs Return Visitors, Engaged Vs Bounced Visitors and Referrals Vs Direct Visitors
    getStatsSummary() {
      let getStatsSummaryParams = {
        Date_End: this.toDate,
        Date_Start: this.fromDate,
        Only_Companies: this.onlyCompanies,
        Zone: this.domainSelected.domainName
      };
      this.$vs.loading();
      this.axios
        .post("/ws/AnalyticsOverview/GetStatsSummary", getStatsSummaryParams)
        .then(response => {
          let data = response.data.SiteOverviewStats;
          let x = [];
          let FirstGroup = [];
          let SecondGroup = [];
          let ThirdGroup = [];

          data.forEach(data => {
            x.push(data.x);

            FirstGroup.push(data.Companies_Identified);
            SecondGroup.push(data.Unique_Visitors);
            ThirdGroup.push(data.Page_Views);
          });

          this.siteTrafficOverviewChartChartSeriesDataOne = FirstGroup
          this.siteTrafficOverviewChartChartSeriesDataTwo = SecondGroup
          this.siteTrafficOverviewChartChartSeriesDataThree = ThirdGroup
          this.siteTrafficOverviewChartChartOptionsXaxis = x

          let firstTimeOrReturnVisitorData = response.data.FirstvsRetunVisitors;

          let firstTimeOrReturnVisitorX = [];
          let firstTimeOrReturnVisitorFirstGroup = [];
          let firstTimeOrReturnVisitorSecondGroup = [];

          if(response.data.FirstvsRetunVisitors){
            firstTimeOrReturnVisitorData.forEach(data => {
              firstTimeOrReturnVisitorX.push(data.x);
              firstTimeOrReturnVisitorFirstGroup.push(data.FirstGroup);
              firstTimeOrReturnVisitorSecondGroup.push(data.SecondGroup);
            });
          }

          this.firstTimeOrReturnVisitorChartSeriesDataOne = firstTimeOrReturnVisitorFirstGroup
          this.firstTimeOrReturnVisitorChartSeriesDataTwo = firstTimeOrReturnVisitorSecondGroup
          this.firstTimeOrReturnVisitorChartOptionsXaxis = firstTimeOrReturnVisitorX

          let engagedOrBouncedVisitorData = response.data.EngagedvsBouncedVisitors;

          let engagedOrBouncedVisitorX = [];
          let engagedOrBouncedVisitorFirstGroup = [];
          let engagedOrBouncedVisitorSecondGroup = [];

          if(response.data.EngagedvsBouncedVisitors){
            engagedOrBouncedVisitorData.forEach(data => {
              engagedOrBouncedVisitorX.push(data.x);
              engagedOrBouncedVisitorFirstGroup.push(data.FirstGroup);
              engagedOrBouncedVisitorSecondGroup.push(data.SecondGroup);
            });
          }

          this.engagedOrBouncedVisitorsChartSeriesDataOne = engagedOrBouncedVisitorFirstGroup
          this.engagedOrBouncedVisitorsChartSeriesDataTwo = engagedOrBouncedVisitorSecondGroup
          this.engagedOrBouncedVisitorsChartOptionsXaxis = engagedOrBouncedVisitorX

          let referralOrDirectVisitorData = response.data.ReferralvsDirectVisitors;

          let referralOrDirectVisitorX = [];
          let referralOrDirectVisitorFirstGroup = [];
          let referralOrDirectVisitorSecondGroup = [];

          if(response.data.ReferralvsDirectVisitors){
            referralOrDirectVisitorData.forEach(data => {
              referralOrDirectVisitorX.push(data.x);
              referralOrDirectVisitorFirstGroup.push(data.FirstGroup);
              referralOrDirectVisitorSecondGroup.push(data.SecondGroup);
            });
          }

          this.referralOrDirectVisitorsChartSeriesDataOne = referralOrDirectVisitorFirstGroup
          this.referralOrDirectVisitorsChartSeriesDataTwo = referralOrDirectVisitorSecondGroup
          this.referralOrDirectVisitorsChartOptionsXaxis = referralOrDirectVisitorX

          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //Visitor Location Overview
    getRegionMapData() {
      let getRegionMapDataParams = {
        Date_End: this.toDate,
        Date_Start: this.fromDate,
        Only_Companies: this.onlyCompanies,
        Zone: this.domainSelected.domainName
      };
      this.$vs.loading();
      this.axios
        .post("/ws/AnalyticsOverview/GetRegionMapData", getRegionMapDataParams)
        .then(response => {
          let data = response.data;
          if (data.Key.length <= 40) {
            this.chartData = [["Country", "Unique Visitors"]];
          } else {
            let locationMapStringData = data.Key.replace(/'/g, '"');
            this.chartData = JSON.parse(locationMapStringData);
          }
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    }
  }
};
</script>
